passwordOne
<template>
    <div class="user-password">
        <div class="edit-login-account" v-if="userInfo.phone">
            <span style="text-align: center;">登录账号：{{userInfo.phone}}</span>
        </div>
        <div class="e-password-item">
            <span class="title">请输入验证码</span>
            <el-input v-model="verificationCode" type="number" placeholder="请输入验证码"></el-input>
            <div class="code-btn" v-show="codeShow" @click="sendPhoneCode">{{codeTitle}}</div>
            <div class="code-btn" v-show="!codeShow">{{timeCount}} 秒</div>
        </div>
        <div class="e-password-item">
            <span class="title">请输入新密码</span>
            <el-input v-model="passwordOne" type="password" show-password placeholder="请输入新密码"></el-input>
        </div>
        <div class="e-password-item">
            <span class="title">确认密码</span>
            <el-input v-model="passwordTwo" type="password" show-password placeholder="请重复输入新密码"></el-input>
        </div>
        <div class="e-password-btn" @click="confirm">确认修改</div>
    </div>
</template>
<script>
    import userRequest from "@/api/user";

    const TIME_COUNT = 60;
    export default {
        created() {
            this.userInfo = JSON.parse(window.localStorage.getItem("userInfo"))
            console.log("当前的个人信息", this.userInfo)
        },
        mounted() {

        },
        data() {
            return {
                userInfo: null,
                passwordOne: "",
                passwordTwo: "",
                verificationCode: "",  // 验证码
                codeTitle: "获取验证码",  // 获取验证码的提示语
                codeShow: true, // 是否展示倒计时
                timeCount: "",       // 倒计时的时间
                timeInter: null,     // 倒计时的interval
            }
        },
        methods: {
            sendPhoneCode() {
                // console.log("点击了发送验证码")
                // const telReg = /^[1]([3-9])[0-9]{9}$/
                // if(!telReg.test(this.userInfo.phone)){
                //     this.$message.error("请检查手机号码是否正确")
                //     return;
                // }
                userRequest.sendPhoneCodeForPassword({phone: this.userInfo.phone}).then(res => {
                    if (res.code === 200) { // 发送成功
                        this.$message.success("验证码发送成功")
                        if (!this.timeInter) {
                            this.timeCount = TIME_COUNT;
                            this.codeShow = false;
                            this.timeInter = setInterval(() => {
                                if (this.timeCount > 0 && this.timeCount <= TIME_COUNT) {
                                    this.timeCount--;
                                } else {
                                    this.codeShow = true;
                                    this.codeTitle = "重新发送"
                                    clearInterval(this.timeInter);
                                    this.timeInter = null;
                                }
                            }, 1000)
                        }
                    } else {
                        this.$message.error("发送失败，请稍后重试")
                    }
                })
            },
            confirm() { // 确认修改密码
                if(this.verificationCode.length!==6){
                    this.$message.warning("请确认验证码")
                    return;
                }

                if(this.passwordOne!==this.passwordTwo){
                    this.$message.warning("两次密码不一致")
                    return;
                }

                userRequest.resetPassword({
                    phone:this.userInfo.phone,
                    password:this.passwordOne,
                    code:this.verificationCode
                }).then(res=>{
                    if(res.code === 200){  // 密码重置成功
                        console.log('密码重置成功',res)
                        this.$message.success("修改成功")
                        window.localStorage.setItem("token","")
                        window.localStorage.setItem("userInfo","")
                        setTimeout()
                    }else{
                        this.$message.warning(res.message)
                    }
                })

            }
        }
    }
</script>
<style lang="scss" scoped>
    @import "../../../assets/css/global.scss";

    .user-password {
        margin-left: 200px;
        width: 400px;

        .edit-login-account {
            font-size: 16px;
            padding-bottom: 20px;
            border-bottom: 1px dashed #d9d9d9;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }

        .e-password-item {
            margin-top: 20px;
            display: flex;
            flex-direction: row;
            position: relative;

            .title {
                min-width: calc(6em + 20px);
            }

            .code-btn {
                position: absolute;
                right: 0px;
                height: 40px;
                line-height: 40px;
                padding: 0px 20px;
                background-color: $main;
                color: #FFFFFF;
                cursor: pointer;
            }
        }

        .e-password-btn {
            cursor: pointer;
            margin-top: 20px;
            border-radius: 4px;
            user-select: none;
            height: 40px;
            line-height: 40px;
            text-align: center;
            color: #fff;
            background: $main;

            &:hover {
                background: $hover;
            }
        }
    }
</style>